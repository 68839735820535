import Iframe from "react-iframe";

export function LookerChart( props ) {
    const {url, width, heigth} = {...props}
            return <iframe 
                width="900"
                height="700"
                src="https://lookerstudio.google.com/embed/reporting/3bb39681-9d1c-418d-8583-17df1e79293d/page/vxkeE"
                frameborder="0" 
                style={{border:0}} 
                allowFullScreen
                sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox">
                </iframe>
}